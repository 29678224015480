.App {
    text-align: left;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: top;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.overPanel {
    z-index: 10;
    border-left: 2px white solid;
    background-color: #303030;
    min-width: 300px;
    max-width: 500px;
}

.editPanel {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px rgb(119, 119, 119) solid;
    background-color: #303030;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.field {
    display: block;
}

.multiline {
    resize: none;
}

.fieldLine {
    display: block;
    margin-top: -12px;
    margin-bottom: 7px;
}

.action {
    font-size: large;
    border: white 2px solid;
    color: white;
    background-color: black;
    display: block;
    width: 100%;
    margin-bottom: 5px;
}

.action:hover {
    color: black;
    background-color: white;
}

.danger {
    border: red 2px solid;
    color: red;
    background-color: black;
}

.danger:hover {
    color: black;
    background-color: red;
}

.notwide {
    width: auto;
    display: inline;
}

.leftPanel {
    position: absolute;
    width: 220px;
    top: 0px;
    left: 0px;
    height: 100%;
    background: "black";
    border-right: white 2px solid;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
}

.listInfoFiller {
    height: 130px;
}

/**/

.listInfo {
    border-bottom: white 2px solid;
    font-size: large;
    position: fixed;
    z-index: 10;
    background: #303030;
    width: 200px;
}

.listInfo p {
    margin: 5px;
    white-space: nowrap;
}

.list {
    width: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: black;
}

.listItem {
    font-size: large;
    border: #303030 2px solid;
    color: white;
    background-color: rgb(37, 37, 37);
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    text-align: justify;
    justify-content: space-between;
}

.listItem:hover {
    color: black;
    background-color: rgb(210, 210, 210);
}

.select {
    background-color: #2a4786;
}

.select:hover {
    background-color: #85abfc;
}

.abilityAtomSelect {
    background-color: #303030;
    color: white;
}

.listItem button {
    border: none;
    font-size: large;
    color: white;
    background-color: rgb(70, 70, 70, 0.5);
    text-align: justify;
    justify-content: space-between;
}

.listItem button:hover {
    color: black;
    background-color: rgb(255, 255, 255, 0.5);
}

.sltBiome {
    min-width: 100px;
}

.listAction {
    width: 95%;
    margin-left: 5px;
    margin-right: 5px;
}

.cardPanel {
    position: absolute;
    left: 230px;
}

.cvsCard {
    width: 500px;
    height: 700px;
    display: block;
}

.lowvisibilitylabel {
    color: rgb(77, 77, 77);
}

.lowvisibility {
    background-color: rgb(77, 77, 77);
    border: none;
}

.lowvisibility::placeholder {
    background-color: rgb(39, 39, 39);
}

.abilityArea {
    border: #bbbbbb 1px solid;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

/* .abilityArea div {
    margin: 0px;
    display: flex;
    text-align: justify;
    justify-content: space-between;
    font-size: large;
}
.abilityArea div span {
    font-size: medium;
}
.abilityArea div input {
    margin-left: 5px;
    max-width: 120px;
}
.abilityArea div input[type="number"] {
    margin-left: 5px;
    max-width: 30px;
}
.abilityArea div div {
    display: block;
} */
.abilityLineArea {
    border: 1px solid rgb(100, 100, 100);
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.abilityAtomName {
    display: block;
    font-size: large;
    display: flex;
    text-align: justify;
    justify-content: space-between;
}

.abilityAtomLine {
    margin: 0px;
    display: flex;
    text-align: left;
    /* justify-content: space-between;
    font-size: medium; */
    font-size: medium;
}

/* .abilityAtomOption div {
    display: block;
} */
.abilityPreview {
    font-size: medium;
    margin-top: 10px;
    border: 2px solid rgb(100, 100, 100);
    padding: 5px;
}

.info {
    font-size: medium;
}

.info select {
    margin-left: 10px;
}

.info button {
    margin-left: 10px;
    position: absolute;
    right: 0px;
}

.colorInput {
    margin-right: 20px;
}

.colorInput input[type=color] {
    margin-left: 5px;
}

.counter {
    display: flex;
    margin-bottom: 10px;
}

.counter input {
    width: 25px;
    text-align: right;
}

.inline {
    display: inline;
    /* margin-left: 5px; */
    margin-right: 5px;
}

.b {
    font-weight: bold;
}

.i {
    font-style: italic;
}

.bufferOnRight {
    margin-right: 10px;
}

.ex {
    border: white 1px solid;
    color: white;
    background-color: black;
    margin-left: 5px;
}

.ex:hover {
    color: black;
    background-color: white;
}

.plusMinus {
    border: white 1px solid;
    color: white;
    background-color: black;
}

.plusMinus:hover {
    color: black;
    background-color: white;
}